$bordercolor : #E3E3E3;
$greycolor: #797979;
$bluecolor: #242331;
$greycolor2: #E3E3E3;
$Bluecolor2: #3A6AD6;
$greycolor3: #EFF2F7;

.Footer {
    padding: 10px;

    .innerFooter {
        border-top: 1px solid $bordercolor;
        border-bottom: 1px solid $bordercolor;
        padding: 20px;

        .col-inside {
            h5 {
                font-size: 20px;
                font-weight: 400;
                color: $greycolor;
                margin-top: 15px;
            }

            h4 {
                color: $greycolor;
                font-size: 22px;
                font-weight: 400;
                margin-bottom: 0px;
            }

            .contactdetail {
                display: flex;
            }
        }

        .col-page-inside {
            padding-left: 50px;

            h3 {
                font-size: 30px;
                font-weight: 700;
                line-height: 38.45px;
                padding: 20px 0px;
                border-bottom: 1px solid $greycolor2;
                color: $bluecolor;
            }

            ul {
                padding-left: 0px;
                list-style-type: none;

                li {
                    margin-top: 15px;

                    a {
                        font-size: 22px;
                        font-weight: 400;
                        color: $greycolor;
                        text-decoration: none;
                        cursor: pointer;

                        &:hover {
                            color: $Bluecolor2 !important;
                        }
                    }
                }
            }
        }

        .box {
            background-color: $greycolor3;
            border-radius: 20px;
            padding: 40px 25px;

            h5 {
                color: $Bluecolor2;
                font-size: 18px;
                font-weight: 700;
            }

            h3 {
                font-size: 25px;
                font-weight: 800;
                line-height: 30.75px;
                color: $bluecolor;
            }

            h4 {
                color: $greycolor;
                font-size: 18px;
                font-weight: 400;
                margin-top: 15px;
            }
            .Links{
                display: flex;
                margin-top: 20px;
                .Link{
                    margin-right: 20px;
                    padding: 5px;
                    svg{
                        color: $Bluecolor2;
                        cursor: pointer;
                        width: 20px;
                        height: 20px;
                    }
                    &:hover{
                        background-color: #fff;
                        border-radius: 6px;
                        svg{
                            color: #ffb422 !important;
                            // color: #000 !important;
                        }
                    }
                }
            }
        }
    }

    .footer-bottom{
        h5{
            text-align: center;
            padding: 15px;
            margin-bottom: 0px;
font-size: 22px;
font-weight: 400;
color: $greycolor;
        }
    }
}

@media screen and (max-width : 991px) and (min-width : 576px) {
    .Footer{
        .innerFooter{
            .col-inside{
                .LOGO{
                    width: 50% !important;
                }
                .contactdetail .mailicon{
                    width: 5% !important;
                }
            }

            .col-page-inside {
                padding-left: 0px !important;
            }
        }
    }
}
@media screen and (max-width : 576px) {
    .Footer{
        .innerFooter{
            padding: 10px !important;
            .col-inside{
                .LOGO{
                    width: 50% !important;
                }
                .contactdetail {

                    .mailicon{
                        width: 10% !important;
                    }
                    .callicon{
                        width: 45% !important; 
                    }
                }
            }

            .col-page-inside {
                padding-left: 0px !important;
            }
        }
    }
}

@media screen and (min-width : 1800px) {
    .Footer{
        .innerFooter {
            .col-inside{
                h4{
                    font-size: 24px;
                }
            }
            .col-page-inside ul li a{
                font-size: 24px;
            }
            .box {

    
                h5 {
                    font-size: 22px;
                }
    
                h3 {
                    font-size: 30px;
                }
    
                h4 {
                    font-size: 20px;
                }
                .Links{
                    .Link{
                        svg{
                            color: $Bluecolor2;
                            cursor: pointer;
                            width: 30px;
                            height: 30px;
                        }
                    }
                }
            }
        }
    }
}