@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');

$fontFamily: "Mulish", sans-serif;
$Bluecolor: #3A6AD6;
$Bluecolor2: #28283C;
$Bluecolor3: #0F0049;
$Bluecolor4: #242331;
$Bluecolor5: #5956E9;
$Bluecolor6: #263238;
$Bluecolor7: #007fff;
$Bluecolor8: #061C3D;
$Greencolor: #2E526B;
$Greencolor2: #E5F4F2;
$background :linear-gradient(100.04deg, #366ED5, #05DFAD);
// $background :linear-gradient(100.04deg, #366ED5 0%, #05DFAD 99.02%);
$headingcolor: #2F2F2F;
$backgroundcolor: #F0F5FF;
$blackcolor: #2D2D2D;
$greycolor: #515164;
$background2: #DBE9FFB2;
$color-grey: #959FBA;
$color-grey2: #E6EDFF;
$color-grey3: #797979;


// common css 
.bluecolor {
    color: $Bluecolor;
}

.greycolor {
    color: $color-grey;
}


h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-family: $fontFamily;
}

.btn1 {
    padding: 12px 30px;
    box-shadow: -2px -2px 4px 0px #00000040 inset, 0px 3px 8px 0px #FFFFFF66 inset;
    border-radius: 22px;
    background-color: $Bluecolor;
    color: #fff;
    text-decoration: none;
    font-size: 24px;
    font-weight: 700;

    &:hover {
        transition: all .1s ease-in;
        box-shadow: none !important;
        background-color: #fff !important;
        color: $Bluecolor !important;
        border: 1px solid $Bluecolor !important;
    }
}

.btn2 {
    padding: 12px 30px;
    border-radius: 8px;
    background-color: $Bluecolor;
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    border: none;

    &:hover {
        transition: all .1s ease-in;
        background-color: #fff !important;
        color: $Bluecolor !important;
        border: 1px solid $Bluecolor !important;
    }
}

.btn3 {
    border: 1px solid $Bluecolor;
    border-radius: 6px;
    background-color: #fff;
    color: $Bluecolor;
    font-size: 14px;
    font-weight: 400;
    padding: 10px;
    cursor: pointer;

    &:hover {
        transition: all .1s ease-in;
        background-color: $Bluecolor !important;
        color: #fff !important;
    }
}

.heading {
    font-size: 45px;
    font-weight: 800;
    text-align: center;
    color: $Bluecolor3;
}

.common {
    &.box {

        background: linear-gradient(180deg, #FFFFFF 4.67%, #a1d9d245 100%);
        // background: linear-gradient(180deg, #E0F2F0 26.5%, #DAE8FF 100%);
        border-radius: 16px;
        padding: 80px 70px;

        .subbox {
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
            // border-radius: 16px;
            padding: 10px 50px 60px;
            // padding: 30px 50px 60px;
            border: 1px solid $Bluecolor;
            border-top: 1px solid #fff;
        }

    }

    &.newbg {
        background: url('../Assets/Simple\ Shiny2.svg') !important;
        // background: url('../Assets/Simple\ Shiny.svg') !important;

        // background: url('../Assets/Snow.svg') !important;
        // background: url('../Assets/Sprinkle\ \(1\).svg') !important;
        .subbox {
            border-radius: 16px;
            border: 1px solid #fff !important;
        }
    }
    &.newbg2 {
       
        background: url('../Assets/Animated\ Shape2.svg') !important;

        // background: url('../Assets/Snow.svg') !important;
        // background: url('../Assets/Sprinkle\ \(1\).svg') !important;
        .subbox {
            border-radius: 16px;
            border: 1px solid #fff !important;
        }
    }
}

.contactmodal {
    display: block;

    .modal-dialog {
        display: block;
        top: 10%;
        margin-right: 0px;

        .modal-header {
            padding: 0px;
            display: block;
            border-radius: 0px;

            .text {
                padding: 12px;

                p {
                    font-size: 20px;
                    margin-bottom: 0px;

                }
            }

            .progress-back {
                background-color: #fff;
                width: 100%;

                .progress-bar {
                    width: 100%;
                    height: 6px;
                    border-radius: 3px;

                }
            }
        }
    }

    &.activeAnimation {
        .progress-back {
            .progress-bar {
                animation: progressAnimation 5s forwards;
            }
        }
    }
}

.hoverablebox {
    padding-left: 30px;

    .accords {
        padding: 5px 0px 35px 60px;

        .heading {
            max-height: 30px;
            overflow: hidden;
            transition: max-height .5s ease;

            .box {
                cursor: pointer;
            }

            h5 {
                text-align: left;
                font-size: 20px;
                font-weight: 500;
                color: rgba(15, 23, 42, 1);
            }

            p {
                text-align: left;
                font-size: 16px;
                font-weight: 400;
                color: rgba(81, 81, 100, 1);

            }
        }

        &.openaccord {
            .heading {
                background-color: #fff;
                padding: 15px;
                max-height: 300px;
                transition: max-height 1.5s ease;
                border-radius: 12px;
                border: 3px solid $Bluecolor7;
                box-shadow: 5px 0px 12px 0px #387ff533;
            }
        }
    }
}


.blobImage {
    &::before {
        position: absolute;
        content: "";
        top: -10%;
        width: 550px;
        height: 600px;
        background: #bbeeca;
        border-radius: 62% 47% 82% 35% / 45% 45% 80% 66%;
        will-change: border-radius, transform, opacity;
        display: block;
        z-index: -1;
        -webkit-animation: sliderShape 5s linear infinite;
        animation: sliderShape 5s linear infinite;
    }
}

@keyframes sliderShape {

    0%,
    100% {
        border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
        transform: translate3d(0, 0, 0) rotateZ(0.01deg);
    }

    34% {
        border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
        transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
    }

    50% {
        transform: translate3d(0, 0, 0) rotateZ(0.01deg);
    }

    67% {
        border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
        transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
    }
}


//  Section Wise CSS 
.Homepage {
    .banner-section {
        position: relative;
        z-index: 1;

        .rowcols {
            padding-top: 150px;

            .colbox {
                h1 {
                    font-size: 55px;
                    font-weight: 800;
                    color: #000;
                }

                p {
                    font-size: 24px;
                    margin-top: 20px;
                    font-weight: 400;
                    color: $Bluecolor2;
                }

                .btns {
                    display: flex;
                    flex-wrap: wrap;

                    .videobtn {
                        display: flex;
                        text-decoration: none;
                        cursor: pointer;

                        .backcircle {
                            z-index: 0;
                            position: absolute;
                            border: 16px solid #fff;
                            border-radius: 50%;
                            width: 70px;
                            height: 70px;
                            border-left: 16px solid;
                            background: linear-gradient(100.04deg, #3A6AD6 0%, #5EEECB 99.02%);
                        }


                        .circle {

                            position: relative;
                            left: 6px;
                            top: 4px;
                            z-index: 1;
                            box-shadow: 5px 8px 14px 0px #0000001F;
                            border-radius: 50%;
                            text-align: center;
                            width: 60px;
                            height: 60px;
                            background-color: #fff;
                            margin-right: 20px;

                            img {
                                margin-top: 12px;
                            }
                        }

                        h5 {
                            align-self: center;
                            color: $Greencolor;
                            font-size: 24px;
                            font-weight: 500;
                            margin-bottom: 0px;
                        }

                        &:hover {
                            .backcircle {
                                -webkit-animation: spin 2s linear infinite;
                                animation: spin 2s linear infinite;

                            }

                            .circle {

                                position: relative;
                                left: 6px !important;
                                top: 5px !important;

                            }



                        }
                    }

                }
            }

            .imgbox {
                position: absolute;
                background: $background;
                border-radius: 16px 0px 0px 16px;
                padding: 50px 0px 50px 40px;
                background-size: 400% 400%;
                animation: flow 5s linear infinite;
            }
        }

        &.circle-shape:before {
            background: linear-gradient(100.04deg, #376ED4 0%, #05DFAD 99.02%);
            border-radius: 50%;
            content: "";
            height: 350px;
            position: absolute;
            left: -100px;
            top: 100px;
            width: 350px;
            z-index: -1;
            filter: blur(150px);
        }
    }

    .about-section {
        .texts {
            padding: 8% 5%;

            h1 {
                font-size: 45px;
                font-weight: 800;
                color: $headingcolor;
            }

            p {
                color: $Bluecolor2;
                font-size: 16px;
                font-weight: 400;
            }
        }
    }

    .impact-section {
        margin-top: 50px;

        .box {
            background: linear-gradient(180deg, #FFFFFF 7.67%, rgba(144, 184, 254, 0.27) 100%);
            border-radius: 16px;
            padding: 61px 72px 30px 72px;


            .boxes {
                margin-top: 35px;
                justify-content: center;

                .subbox {
                    margin-top: 15px;

                    h4 {
                        color: $Bluecolor4;
                        font-size: 45px;
                        font-weight: 800;
                        text-align: center;

                        span {
                            color: $Bluecolor;
                        }
                    }

                    p {
                        color: $Bluecolor4;
                        font-size: 16px;
                        font-weight: 500;
                        text-align: center;

                    }
                }
            }
        }
    }

    .features-section {
        margin-top: 100px;

        // position: relative;
        .feature-card {

            .box {
                position: relative;
                margin-top: 30px;
                border-radius: 12px;
                background-color: $backgroundcolor;
                padding: 25px 20px 25px 30px;

                .heading {
                    display: flex;

                    .imgBox {

                        background-color: #fff;
                        border-radius: 12px;
                        margin-right: 15px;
                        padding: 13px;
                        display: flex;
                        align-self: baseline;
                        flex-shrink: 0;

                        img {
                            align-self: baseline;

                        }
                    }

                    h5 {

                        font-size: 20px;
                        font-weight: 700;
                        align-self: center;
                        text-align: left;
                        color: $blackcolor;
                    }
                }

                h6 {

                    font-size: 16px;
                    font-weight: 400;
                    text-align: left;
                    margin-top: 10px;
                    line-height: 25px;
                    color: $headingcolor;
                }

                .hiddentexts {
                    max-height: 0;
                    overflow: hidden;
                    transition: max-height 0.3s ease-in-out;

                    .subbox {
                        display: flex;
                        margin-bottom: 15px;

                        img {
                            align-self: baseline;
                            margin-right: 10px;
                        }
                    }

                    &.show {
                        margin-bottom: 40px;
                        max-height: 500px;
                    }
                }

                .arrowbox {
                    margin-bottom: 8px;
                    cursor: pointer;
                    position: absolute;
                    right: 20px;
                    bottom: 2px;
                    background-color: #fff;
                    border-radius: 8px;
                    padding: 10px;

                    span {
                        svg {
                            color: $Bluecolor;
                            width: 30px;
                            height: 30px;
                            transform: rotate(45deg);
                            transition: transform 0.2s ease-in;
                        }
                    }
                }
            }

            &:hover {
                // &.active {

                //    position: absolute;
                //    z-index: 5;
                //     transform: scale(1.1) translateX(80%);
                .box {
                    background-color: $Bluecolor !important;

                    .heading .imgBox img {
                        -webkit-animation: spinY 2s linear infinite;
                        animation: spinY 2s linear infinite;
                    }

                    h5,
                    h6 {
                        color: #fff !important;
                    }

                    span {
                        svg {
                            transform: rotate(-135deg);
                        }
                    }
                }
            }
        }
    }

    .benefits-section {
        margin-top: 80px;

        .box {
            background: linear-gradient(180deg, #FFFFFF 7.67%, rgba(144, 184, 254, 0.27) 100%);
            border-radius: 16px;
            padding: 61px 72px 30px 72px;

            .boxes {
                margin-top: 35px;
                justify-content: center;

                .subbox {
                    margin-top: 15px;

                    .box1 {
                        position: relative;
                        text-align: center;

                        img {
                            margin-bottom: 20px;
                        }

                        h4 {
                            position: absolute;
                            top: 25%;
                            left: 35%;
                            font-size: 35px;
                            font-weight: 700;

                            text-align: center;
                            color: #000;
                        }

                        p {
                            color: $Bluecolor3;
                            font-size: 16px;
                            font-weight: 500;
                            text-align: center;

                        }
                    }
                }
            }
        }
    }

    .productivity-section {
        margin-top: 100px;

        .sectioncards {
            margin-top: 30px;

            .cards {
                padding: 15px;

                .box {
                    margin-top: 20px;
                    padding: 35px;
                    border-radius: 40px;
                    // height: 460px;
                    background: #fff;
                    transition: background 0.3s ease-in;
                    border: 1px solid #A6ABBD;

                    .subbox {
                        text-align: center;

                        img {
                            margin: 0px auto;
                            width: 55%;
                        }

                        h5 {
                            font-size: 20px;
                            font-weight: 700;
                            color: $Bluecolor4;
                        }
                    }

                    .lists {
                        .subboxes {
                            display: flex;

                            img {
                                width: 5%;
                                height: 5%;
                                align-self: baseline;
                                margin-top: 10px;
                                margin-right: 20px;
                            }

                            h5 {

                                font-size: 16px;
                                font-weight: 400;
                                line-height: 25.18px;

                            }
                        }
                    }

                    &:hover {
                        border: none;
                        box-shadow: 5px 5px 10px 0px #A6ABBD, -5px -5px 10px 0px #FAFBFF;
                        background: linear-gradient(180deg, #E0F2F0 26.5%, #DAE8FF 100%);
                    }
                }
            }

            .owl-carousel {
                .owl-nav {
                    .owl-prev {
                        background-color: $Bluecolor5;
                        border-radius: 12px;
                        padding: 8px 15px 4px !important;

                        span {
                            content: url('../Assets/arrow-left.png');
                        }
                    }

                    .owl-next {
                        background-color: $Bluecolor5;
                        border-radius: 12px;
                        padding: 8px 15px 4px !important;

                        span {
                            content: url('../Assets/arrow-right.png');
                        }
                    }

                    & [class*='owl-'] {
                        margin: 10px;
                    }
                }
            }
        }
    }

    .why-section {
        margin-top: 80px;

        .box {
            background: linear-gradient(180deg, #FFFFFF 7.67%, rgba(144, 184, 254, 0.27) 100%);
            border-radius: 16px;
            padding: 61px 72px 30px 72px;

            .boxes {
                margin-top: 55px;
                justify-content: center;

                .subbox {
                    margin-top: 15px;

                    .box1 {
                        text-align: center;

                        h4 {
                            margin-top: 20px;
                            color: $Bluecolor6;
                            font-size: 20px;
                            font-weight: 700;
                            line-height: 20px;

                        }

                        p {
                            color: $greycolor;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 20.08px;
                            margin-top: 15px;
                        }
                    }
                }
            }
        }
    }

    .workflow-section {
        margin-top: 80px;

        .rowss {
            margin-top: 30px;

            .imgs {
                background: linear-gradient(100.04deg, rgba(58, 106, 214, 0.6), rgba(5, 223, 173, 0.6));
                // background: linear-gradient(100.04deg, rgba(58, 106, 214, 0.6) 0%, rgba(5, 223, 173, 0.6) 99.02%);
                border-radius: 16px;
                padding: 50px 30px;
                animation: flow 5s linear infinite;
                background-size: 400% 400%;
            }
        }
    }

    .offering-section {
        margin-top: 100px;

        .texts {
            p {
                margin-top: 15px;
                font-size: 20px;
                font-weight: 400;
                line-height: 30px;
            }

            .boxes {
                display: flex;
                flex-wrap: wrap;

                .box1 {
                    width: 160px;
                    margin-top: 20px;
                    box-shadow: 42.59px 36.2px 59.07px 0px #3366FF0D;
                    padding: 20px 20px 10px 20px;
                    text-align: center;
                    border-radius: 24px;
                    background-color: #fff;
                    border: 1.22px solid $Greencolor2;

                    .square {
                        margin: 0px auto;
                        background-color: $Greencolor2;
                        border-radius: 15px;
                        padding: 15px;
                        width: 61px;
                        height: 61px;
                    }

                    h5 {

                        font-size: 24.44px;
                        font-weight: 600;
                        line-height: 36.66px;
                        color: $blackcolor;
                    }
                }
            }
        }
    }

    .freeTrial-section {
        margin-top: 100px;

        .box {

            position: relative;

            p {
                font-size: 20px;
                font-weight: 400;
                line-height: 31.48px;
                text-align: center;
            }

            .LineGraph {
                background-color: #fff;
                margin-top: 20px;
                padding: 20px;
                border-radius: 16px;

                .Filters {
                    h5 {
                        font-size: 14px;
                        font-weight: 400;
                        margin-bottom: 0px;
                    }

                    .select-box {
                        margin-top: 5px;
                        width: 90%;
                        background-color: $background2;
                        border: none;
                        border-radius: 6px;
                        padding: 10px;
                        font-size: 14px;
                        font-weight: 400;

                        &:focus-visible {
                            outline: none !important;
                        }
                    }

                    .symboldiv {
                        align-self: center;
                        margin-top: 10%;

                        .symbol {
                            font-Weight: 400;
                            font-Size: 18px;
                        }
                    }
                }

                .graph {
                    margin-top: 30px;
                    border-radius: 12px;
                    border: 1px solid $color-grey;
                    padding: 20px 15px;

                    .heading {
                        justify-content: space-between;
                        flex-wrap: wrap;

                        .lefttext {
                            h5 {
                                font-size: 20px;
                                font-weight: bold;
                                line-height: 30px;
                            }
                        }

                        .righttext {
                            display: flex;
                            flex-wrap: wrap;

                            .typegraph {
                                align-self: baseline;
                                background-color: #A8C8FF;
                                color: #0F0049;
                                border-radius: 8px;
                                padding: 8px;

                                h5 {
                                    font-size: 18px;
                                    margin-bottom: 0px;
                                }
                            }

                            .legend {
                                display: flex;

                                .circle {
                                    width: 8px;
                                    height: 8px;
                                    border-radius: 50%;
                                    align-self: center;
                                }

                                h5 {
                                    font-size: 14px;
                                    font-weight: 400;
                                    align-self: center;
                                    margin-bottom: 0px;
                                }
                            }
                        }
                    }
                }
            }


            .GetStarted {
                position: absolute;
                width: 90%;
                margin-top: -35px;

                .emails {
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;

                    .input-field {
                        padding: 20px 18px;
                        border-radius: 8px;
                        background-color: #fff;
                        border: none;
                        margin-right: 40px;
                        width: 40%;

                        &:focus-visible {
                            outline: none !important;
                        }
                    }

                    .btnss {
                        border-radius: 8px !important;
                        border: none;
                    }
                }
            }
        }
    }
}

.About-section {
    padding-top: 100px;

    .banner-about {
        .box {
            position: relative;


            p {
                font-size: 20px;
                font-weight: 400;
                line-height: 31.48px;
                text-align: center;
            }



            .GetStarted {
                position: absolute;
                width: 90%;
                margin-top: -60px;
                display: flex;
                justify-content: center;

                .tabs {
                    background-color: #fff;
                    border-radius: 16px;
                    padding: 15px;
                    width: 85%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-around;

                    .subtabs {
                        h4 {
                            font-size: 45px;
                            font-weight: 800;
                            line-height: 49.5px;
                            text-align: center;

                            span {
                                color: $Bluecolor;
                            }
                        }

                        p {
                            color: $Bluecolor3;
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 25.18px;
                            text-align: center;

                        }

                    }
                }
            }
        }
    }

    .mission-section {
        margin-top: 100px;

        .texts {
            padding: 20px 60px;

            p {
                color: $color-grey3;
                font-size: 20px;
                font-weight: 400;
                line-height: 31.48px;
                text-align: left;

            }
        }
    }

    .value-section {
        margin-top: 80px;


        .box-content {
            border-radius: 30px;
            padding: 39px 35px;
            background-color: #fff;

            display: flex;

            .leftside {
                margin-right: 30px;

                .square {
                    width: 90px;
                    height: 90px;
                    border-radius: 18px;
                    padding: 16px;
                }
            }

            .rightside {
                h6 {
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 32.6px;
                    text-align: left;
                    color: $Bluecolor2;
                    margin-bottom: 0px;
                }

                p {
                    margin-bottom: 0px;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 26.08px;
                    text-align: left;
                    color: $Bluecolor2;
                }
            }
        }


    }

    .solution-section {
        margin-top: 100px;

        p {
            color: $blackcolor;
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
            text-align: center;

        }

        .boxess {
            display: flex;
            justify-content: center;

            .box {
                padding: 40px;
                border-radius: 12px;
                border: 1px solid #EDEFFF;
                box-shadow: 34.85px 29.63px 48.34px 0px #3366FF0D;
                transition: box-shadow .3s ease-in;

                h5 {
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 32.6px;
                    color: #28283C;
                    margin-bottom: 0px;
                }

                h6 {
                    margin-bottom: 0px;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 26.08px;
                    color: #28283C;
                    font-size: 18px;
                }

                &:hover {
                    box-shadow: 8px 8px 1px 2px $Bluecolor;
                }
            }
        }
    }

    .zerodue-solution {
        margin-top: 100px;

        .box {
            padding: 50px 20px !important;

            .texts {
                .box1 {
                    margin-top: 20px;

                    .subboxes {
                        margin-top: 20px;
                        display: flex;

                        .textline {
                            h5 {
                                color: #191A15;
                                margin-bottom: 0px;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 30px;
                                text-align: left;

                            }
                        }
                    }
                }
            }
        }
    }
}

.Error-Page {
    padding-top: 100px;

    .text {
        padding: 40px 20px;
        text-align: center;

        h1 {
            font-size: 300px;
            font-weight: 800;
            line-height: 330px;
            background: linear-gradient(100.04deg, #007BFF 0%, #05DFAD 99.02%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            color: transparent;
        }

        h2 {
            color: $Bluecolor4;
            font-size: 75px;
            font-weight: 800;
            line-height: 82.5px;

        }

        h5 {
            margin: 20px 0px 30px;
            font-size: 20px;
            font-weight: 400;
            line-height: 31.48px;
            color: $color-grey3;
        }
    }
}

.solution-Page {
    padding-top: 120px;

    .solution-banner {
        .subbox {
            padding: 10px 50px 0px !important;

            p {
                margin-top: 20px;
                font-size: 22px;
                font-weight: 400;
                line-height: 33.11px;
                text-align: center;
                color: $Bluecolor2;

                span {
                    font-weight: 700 !important;
                    color: $Bluecolor;
                }
            }
        }
    }

    .Flow-chart {
        margin-top: 100px;

        .chart {
            h6 {

                font-size: 35px;
                font-weight: 700;
                color: $Bluecolor6;
            }
        }
    }

    .Process {
        background: linear-gradient(180deg, #E0F2F0 26.5%, #DAE8FF 100%);
        margin-top: 100px;
        padding: 70px 50px;

        .texts {
            padding: 20px 90px 40px 150px;

            .toptext {
                position: relative;
                display: flex;
                flex-wrap: wrap;

                h1 {
                    z-index: 0;
                    position: absolute;
                    top: -40px;
                    right: 0;
                    font-size: 120px;
                    color: #fff;
                    font-weight: 700;
                }

                h3 {
                    z-index: 1;
                    white-space: nowrap;
                    align-self: center;
                    font-size: 45px;
                    font-weight: 700;
                    line-height: 67px;
                    color: $Bluecolor3;
                }
            }

            p {
                font-size: 20px;
                font-weight: 400;
                line-height: 33px;
                color: $Bluecolor3;
            }

        }

        .ProcessFlow {
            margin-top: 40px;
            color: $Bluecolor6 ;
            font-size: 22px;
            font-weight: 700;
            line-height: 20px;

        }

        .cardss {
            .cardbox {
                background-color: #fff;
                padding: 20px;
                border-radius: 16px;
                border: 1px solid #E6E8EC80;

                .topbox {
                    .square {
                        width: 64px;
                        height: 64px;
                        border-radius: 8px;
                        padding: 16px;
                    }
                }

                .textdiv {
                    margin-top: 20px;

                    h4 {
                        color: $Bluecolor8;
                        font-size: 20px;
                        font-weight: 700;
                        line-height: 26px;

                    }

                    p {
                        color: #42526B;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 25px;

                    }
                }

                &:hover {
                    transition: box-shadow .3s ease-in;
                    box-shadow: 5px 5px 5px -1px $Bluecolor;

                }
            }
        }

        .texts2 {
            padding: 20px 220px 40px 0px;
            position: relative;

            h1 {
                top: -15%;
                z-index: 1;
                position: absolute;
                font-size: 120px;
                color: #fff;
                font-weight: 700;
                margin-bottom: 0px;
            }

            h3 {
                position: relative;
                z-index: 2;
                font-size: 45px;
                font-weight: 700;
                line-height: 67px;
                color: $Bluecolor3;
            }

            p {
                font-size: 20px;
                font-weight: 400;
                line-height: 33px;
                color: $Bluecolor3;
            }

        }
    }
}

.cms-solution-page {
    padding-top: 120px;

    .cms-banner {
        .subbox {
            padding: 10px 50px 0px !important;

            p {
                margin-top: 20px;
                font-size: 22px;
                font-weight: 400;
                line-height: 33.11px;
                text-align: center;
                color: $Bluecolor2;

                span {
                    font-weight: 700 !important;
                    color: $Bluecolor;
                }
            }
        }
    }

    .cms-feature-section {
        background: linear-gradient(180deg, #E0F2F0 26.5%, #DAE8FF 100%);
        margin-top: 30px;
        margin-bottom: 30px;
        padding: 20px 40px 30px;

        .rowss {
            .textImages {
                padding-right: 40px;
                position: relative;

                .behindtext {
                    position: absolute;
                    z-index: 0;
                    color: #fff;
                    font-size: 120px;
                    font-weight: 700;
                    line-height: 33px;
                    text-align: left;
                }

                .head {
                    position: relative;
                    z-index: 2;
                    font-size: 40px;
                    color: #000;
                    font-weight: 600;
                    line-height: 58.5px;
                    letter-spacing: -0.02em;
                    text-align: left;

                }
            }

            .Tabbuttons {
                margin-bottom: 30px;
                flex-wrap: wrap;

                .tab {
                    border-radius: 8px;
                    background-color: #fff;
                    color: #000;
                    padding: 12px 40px;
                    cursor: pointer;

                    &.active {
                        background-color: $Bluecolor !important;
                        color: #fff !important;
                        box-shadow: 34.85px 29.63px 48.34px 0px #3366FF0D;
                    }
                }
            }
        }
    }
}

.offerings-page {
    padding-top: 120px;

    .offering-banner {
        p {
            font-size: 20px;
            font-weight: 400;
            line-height: 30.1px;
            color: $Bluecolor2;
        }
    }

    .partner {
        margin-top: 100px;

        .texts {
            position: relative;
            text-align: center;

            .behindtexts {
                font-size: 200px;
                font-weight: 700;
                background: linear-gradient(180deg, #E0F2F0 26.5%, #DAE8FF 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                color: transparent;
            }

            .head {
                text-align: center;
                position: relative;
                z-index: 2;
                margin-top: -10%;
                font-size: 45px;
                font-weight: 600;
                line-height: 58.5px;
                color: #000;
            }

            .para {
                color: $blackcolor;
                font-size: 22px;
                font-weight: 300;
                line-height: 28.6px;

            }

        }
    }

    .benefits {
        margin-top: 100px;

        .textsBox {
            h3 {
                font-family: Mulish;
                font-size: 31px;
                font-weight: 700;
                line-height: 46.5px;
                text-align: center;
                color: $blackcolor;
            }

            .boxess {
                border-radius: 14px;
                background-color: #FDFFFF;
                box-shadow: 4px 6px 6px 0px #E2F3F1;
                display: flex;
                padding: 40px 25px;
                transition: background-color .2s ease-in;

                .imgs {
                    margin-right: 15px;
                }

                .textwithicon {
                    h5 {
                        color: #000;
                        font-size: 24px;
                        font-weight: 600;
                        line-height: 31.2px;
                        text-align: left;

                    }

                    p {
                        color: #000;
                        font-size: 21px;
                        font-weight: 300;
                        line-height: 27.3px;
                        text-align: left;

                    }
                }

                &:hover {
                    background-color: #E2F3F1 !important;
                    box-shadow: 4px 6px 6px 0px #54bd95;
                }
            }
        }
    }

    .whocanJoin1 {
        margin-top: 100px;

        .text-data {
            padding: 10px;

            h3 {

                font-size: 30px;
                font-weight: 700;
                line-height: 67px;
                text-align: left;
                color: $Bluecolor8;
            }

            h4 {
                font-size: 31px;
                font-weight: 500;
                line-height: 33px;
                text-align: left;
                color: $Bluecolor8;
                margin-top: 20px;
                margin-bottom: 5px;
            }

            h6 {
                font-size: 22px;
                font-weight: 300;
                line-height: 33px;
                text-align: left;
                color: $Bluecolor8;

            }
        }
    }

    .backgroundbelow {
        background: linear-gradient(360deg, #DAE7FE 0%, #FFFFFF 108.65%);
        padding-bottom: 50px;
    }
}

.Policy {
    padding-top: 120px;

    p {
        margin-top: 15px;
        font-size: 20px;
        font-weight: 400;
        line-height: 30.1px;
        text-align: center;
        color: $Bluecolor2;
    }

    .policybox {

        padding: 10px;

        .cardbox {
            margin-top: 25px;
            background-color: #fff;
            border-radius: 12px;
            box-shadow: 0px 4px 4px 0px #00000040;
            padding: 15px 30px;
            transition: background-color .3s ease-in;

            h4 {
                font-size: 20px;
                font-weight: 700;
                line-height: 22px;
                text-align: left;
                color: #000;
            }

            h6 {
                margin-bottom: 0px;
                font-size: 16px;
                font-weight: 400;
                line-height: 24.08px;
                text-align: left;
                color: $Bluecolor2;
            }

            &:hover {
                background-color: $Bluecolor !important;

                h4,
                h6 {
                    color: #fff !important;
                }
            }
        }
    }

    .bottomText {
        margin-top: 40px;
        font-size: 14px;
        font-weight: 400;
        line-height: 21.07px;
        text-align: center;
        color: #fff;
        // color: $color-grey3;
    }
}

.contact-page {
    padding-top: 120px;

    .contact-box {
        padding: 40px;
        // background: linear-gradient(180deg, #E0F2F0 26.5%, #DAE8FF 100%);
        background: url('../Assets/Simple\ Shiny.svg');
        border-radius: 16px;

        .sub-box {
            padding: 30px;
            border: 1px solid #fff;
            // border: 1px solid $Bluecolor;
            border-radius: 8px;

            .contact-form {
                background-color: #fff;
                border-radius: 8px;
                border-left: 1px solid #00000033;
                padding: 24px;
                padding-top: 0px;

                .form-grp {
                    .labeltext {
                        color: #000000CC;
                        display: block;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 18px;
                        text-align: left;

                    }

                    .inputfield {
                        margin-top: 10px;
                        width: 100%;
                        border: none;
                        border-bottom: 1px solid #00000033;
                        padding: 10px;
                        color: #00000066;
                        font-size: 20px;
                        font-weight: 400;
                        line-height: 23px;
                        text-align: left;

                        &:focus-visible {
                            outline: none !important;
                        }
                    }
                }
            }

            .address {
                margin-top: 40px;

                .address-details {
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 30.1px;
                    text-align: center;
                    color: $Bluecolor2;

                    span {
                        font-weight: 700 !important;
                        font-size: 22px !important;

                    }
                }
            }
        }
    }
}




@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes spinY {
    0% {
        transform: rotateY(0deg) rotateZ(0deg);
    }


    50% {
        transform: rotateY(360deg) rotateZ(45deg);
    }

    100% {
        transform: rotateY(0deg) rotateZ(0deg);
    }
}

@keyframes flow {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}


@keyframes progressAnimation {
    from {
        width: 100%;
    }

    to {
        width: 0%;
    }
}


@media screen and (min-width: 1200px) {
    .Homepage {
        .banner-section {
            .rowcols {
                .colbox {
                    padding: 10% 0% !important;
                }

            }
        }

        .impact-section {
            .box {
                .boxes {
                    .subbox {
                        width: calc(100%/5) !important;
                    }
                }
            }
        }
    }
}

@media screen and ((max-width: 1200px) and (min-width:576px)) {
    .Homepage {
        .about-section {
            .texts {
                padding: 5% !important;
            }
        }

        .benefits-section {
            .box {
                .boxes .subbox .box1 {
                    h4 {

                        top: 18% !important;

                    }
                }
            }
        }


        .hoverablebox {
            .accords {
                padding: 5px 0px 25px 0px !important;
            }
        }

    }

    .About-section {
        .banner-about {
            .box {
                .GetStarted {
                    position: relative !important;
                    width: 100% !important;
                    margin-top: -60px;

                    .tabs {
                        width: 100% !important;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .blobImage {
        text-align: center;

        &::before {
            width: 300px !important;
            height: 350px !important;

        }
    }

    .common.box {
        .subbox {
            .heading {
                font-size: 40px !important;
            }
        }
    }

    .Homepage {
        .banner-section {
            .rowcols {
                .colbox {
                    h1 {
                        font-size: 40px !important;
                    }

                    h5,
                    a,
                    p {
                        font-size: 20px !important;
                    }
                }

            }
        }

        .about-section {
            .texts {
                padding: 5% !important;
            }
        }

        .impact-section {
            .box {
                padding: 61px 20px 30px 20px !important;
            }
        }

        .benefits-section {
            .box {
                .boxes .subbox .box1 {
                    h4 {

                        top: 13% !important;

                    }
                }
            }
        }


        .hoverablebox {
            padding-left: 0px !important;

            .accords {
                padding: 5px 0px 20px 0px !important;

                .heading {
                    h5 img {
                        width: 5% !important;
                    }
                }
            }

        }

        .offering-section {
            margin-top: 100px;
            text-align: center !important;

            .texts {

                h1 {
                    text-align: center !important;
                }

                .boxes {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                }
            }
        }

        .freeTrial-section {

            .box {

                padding: 30px 5px 60px !important;

                .subbox {

                    padding: 10px 10px 60px;

                    .LineGraph {
                        padding: 12px;
                    }
                }

                .GetStarted {
                    position: relative !important;
                    width: 100% !important;
                    margin-top: 0px !important;

                    .emails {

                        .input-field {
                            margin-top: 10px !important;
                            margin-right: 0px !important;
                            width: 90%;
                        }

                        .btnss {
                            margin-top: 10px !important;
                        }
                    }
                }
            }
        }
    }

    .About-section {
        .banner-about {
            .box {
                padding: 50px 10px !important;

                .subbox {
                    padding: 10px 10px 60px !important;
                }

                .GetStarted {

                    position: relative !important;
                    width: 100% !important;
                    margin-top: 10px !important;

                    .tabs {
                        width: 100% !important;
                    }
                }
            }
        }

        .value-section {

            .box {
                padding: 80px 10px !important;

                .subbox {
                    padding: 10px 10px 60px !important;

                    .box-content {
                        padding: 39px 20px !important;
                        flex-wrap: wrap !important;
                    }
                }
            }

        }

        .solution-section {
            .boxess {
                .box {
                    padding: 20px !important;
                }
            }
        }

        .zerodue-solution {
            .box {

                padding: 30px 5px 60px !important;

                .subbox {

                    padding: 10px 10px 60px !important;

                    .texts {
                        .box1 .subboxes .imgs img {
                            width: 80% !important;
                        }
                    }
                }
            }
        }
    }

    .Error-Page {

        .text {
            h1 {
                font-size: 150px !important;

            }

            h2 {
                font-size: 60px !important;
            }
        }
    }

    .solution-Page {

        .solution-banner {
            .common.box {
                padding: 50px 10px !important;
            }

            .subbox {
                padding: 10px 10px 0px !important;
            }
        }

        .Flow-chart {
            .flowchart {
                width: 80% !important;
            }

            .partyicon {
                width: 35% !important;
            }
        }

        .Process {
            padding: 70px 6px !important;

            .texts {
                h3 {
                    white-space: wrap !important;
                }
            }
        }
    }

    .cms-solution-page {
        padding-top: 120px;

        .cms-banner {
            .common.box {
                padding: 50px 10px !important;

                .subbox {
                    padding: 10px 10px 0px !important;
                }
            }
        }

        .cms-feature-section {

            padding: 20px 12px 30px !important;

            .rowss {
                .textImages {
                    padding-right: 0px !important;
                }

                .hoverablebox {
                    padding-left: 0px !important;

                    .accords {
                        .heading {
                            .box {
                                img {
                                    width: 6% !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .offerings-page {
        .offering-banner {
            .common.box {
                padding: 50px 10px !important;

                .subbox {
                    padding: 10px 10px 0px !important;
                }
            }
        }

        .benefits {
            .textsBox {
                .boxess {
                    padding: 30px 15px !important;
                }
            }
        }
    }

    .contact-page {
        .contact-box {
            padding: 40px 12px !important;

            .sub-box {
                padding: 30px 6px !important;
            }
        }
    }
}

@media screen and (max-width :991px) {

    .common.box {
       &.padding-dec{
        padding: 80px 20px !important;
        .subbox{
            padding: 10px 20px 60px !important;
        }
       }
    }
    .About-section {
        .mission-section {
            .texts {
                padding: 20px 10px !important;
            }

            #coltext {
                order: 2 !important;
            }

            #colimage {
                order: 1 !important;
            }
        }
    }

    .solution-Page {
        .Process {

            #colss1 {
                order: 2 !important;
            }

            #colss2 {
                order: 1 !important;

            }
        }
    }

    .Policy {
        .common.box {
            padding: 30px 6px !important;

            .subbox {
                padding: 10px 6px 60px !important;

                .policybox {
                    padding: 3px !important;
                }
            }
        }

    }
}

@media screen and (max-width :1200px) {
    .solution-Page {
        .Process {
            .texts {
                padding: 12px 0px !important;
            }

            .texts2 {
                padding: 12px 0px !important;
            }
        }
    }

    .Homepage {
        .productivity-section {
            .sectioncards {
                .cards {
                    .box {
                        height: 640px !important;
                    }
                }


            }
        }
    }
}

@media screen and (max-width :350px) {
    .Homepage {
        .productivity-section {
            .sectioncards {
                .cards {
                    .box {
                        height: 650px !important;
                        padding: 35px 20px !important;
                    }
                }


            }
        }
    }
}

@media screen and (min-width: 991px) and (max-width :1200px) {
    .blobImage {
        &::before {
            width: 400px !important;
            height: 480px !important;
        }
    }
}

@media screen and (min-width :1800px) {
    .btn2 {
        font-size: 22px;
    }

    .heading {
        font-size: 50px;
    }

    .hoverablebox {

        .accords {
            .heading {
                h5 {

                    font-size: 24px;
                }

                p {
                    font-size: 20px;
                }
            }
        }
    }

    .Homepage {
        .banner-section {
            .rowcols {
                .colbox {
                    h1 {
                        font-size: 60px;
                    }

                    p {
                        font-size: 30px;
                    }

                    .btns {
                        .videobtn {
                            h5 {
                                font-size: 30px;
                            }

                            &:hover {
                                .backcircle {
                                    -webkit-animation: spin 2s linear infinite;
                                    animation: spin 2s linear infinite;

                                }

                                .circle {

                                    position: relative;
                                    left: 6px !important;
                                    top: 5px !important;

                                }



                            }
                        }

                        .btn1 {
                            font-size: 30px;
                        }

                    }
                }

                .imgbox {
                    padding: 30px 0px 30px 40px;
                }
            }
        }

        .about-section {
            .texts {
                h1 {
                    font-size: 60px;
                }

                p {
                    font-size: 20px;
                }
            }
        }

        .impact-section {
            margin-top: 70px;

            .box {
                .boxes {
                    .subbox {

                        h4 {
                            font-size: 60px;
                        }

                        p {
                            font-size: 20px;
                        }
                    }
                }
            }
        }

        .features-section {
            margin-top: 120px;

            .feature-card {

                .box {

                    .heading {
                        h5 {

                            font-size: 22px;
                        }
                    }

                    h6 {

                        font-size: 20px;
                        margin-top: 20px;
                    }
                }
            }
        }

        .benefits-section {
            margin-top: 100px;

            .box {

                .boxes {

                    .subbox {
                        .box1 {

                            img {
                                margin-bottom: 20px;
                            }

                            h4 {
                                font-size: 37px;
                            }

                            p {

                                font-size: 20px;

                            }
                        }
                    }
                }
            }
        }

        .productivity-section {
            margin-top: 120px;

            .sectioncards {
                .cards {
                    .box {
                        height: 680px !important;

                        .subbox {
                            h5 {
                                font-size: 24px;
                            }
                        }

                        .lists {
                            .subboxes {
                                h5 {
                                    font-size: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .why-section {
            margin-top: 100px;

            .box {
                .boxes {
                    .subbox {
                        .box1 {
                            h4 {
                                font-size: 24px;

                            }

                            p {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }

        .workflow-section {
            margin-top: 100px;
        }

        .offering-section {
            margin-top: 120px;

            .texts {
                p {
                    font-size: 22px;
                }
            }
        }

        .freeTrial-section {
            margin-top: 120px;

            .box {
                p {
                    font-size: 22px;
                }

            }
        }
    }

    .About-section {
        padding-top: 120px;

        .banner-about {
            .box {
                p {
                    font-size: 22px;
                }



                .GetStarted {
                    .tabs {
                        .subtabs {
                            h4 {
                                font-size: 50px;
                            }

                            p {
                                font-size: 18px;
                            }

                        }
                    }
                }
            }
        }

        .mission-section {
            margin-top: 120px;

            .texts {
                p {
                    font-size: 22px;
                }
            }
        }

        .value-section {
            margin-top: 100px;


            .box-content {

                .rightside {
                    h6 {
                        font-size: 24px;
                    }

                    p {
                        font-size: 20px;
                    }
                }
            }


        }

        .solution-section {
            margin-top: 120px;

            p {
                font-size: 22px;
            }

            .boxess {
                display: flex;
                justify-content: center;

                .box {
                    height: 540px !important;

                    h5 {
                        font-size: 24px;
                        font-weight: 700;
                        line-height: 32.6px;
                        color: #28283C;
                        margin-bottom: 0px;
                    }

                    h6 {
                        margin-bottom: 0px;
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 26.08px;
                        color: #28283C;
                        font-size: 18px;
                    }

                    a {
                        font-size: 17px;
                    }
                }
            }
        }

        .zerodue-solution {
            margin-top: 120px;

            .box {
                .texts {
                    .box1 {

                        .subboxes {

                            .textline {
                                h5 {
                                    font-size: 20px;

                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .solution-Page {
        padding-top: 140px;

        .solution-banner {
            .subbox {
                p {
                    font-size: 24px;
                }
            }
        }

        .Flow-chart {
            margin-top: 120px;

            .chart {
                h6 {
                    font-size: 40px;
                }
            }
        }

        .Process {
            margin-top: 120px;

            .texts {

                p {
                    font-size: 24px;
                }

            }

            .ProcessFlow {
                font-size: 24px;
            }

            .cardss {
                .cardbox {
                    height: 290px !important;

                    .textdiv {

                        h4 {
                            font-size: 24px;
                        }

                        p {
                            font-size: 20px;
                        }
                    }
                }
            }

            .texts2 {
                p {
                    font-size: 24px;
                }

            }
        }
    }

    .cms-solution-page {
        padding-top: 140px;

        .cms-banner {
            .subbox {
                p {
                    font-size: 23px;

                }
            }
        }

        .cms-feature-section {
            margin-top: 50px;

            .textImages {
                p {
                    font-size: 20px;
                }

                img {
                    width: 60% !important;
                }
            }
        }
    }

    .offerings-page {
        padding-top: 140px;

        .offering-banner {
            p {
                font-size: 22px;
            }
        }

        .partner {
            margin-top: 120px;

            .texts {

                .para {
                    font-size: 24px;
                }

            }
        }

        .benefits {
            margin-top: 120px;

            .textsBox {
                h3 {
                    font-size: 33px;
                }

                .boxess {
                    .textwithicon {
                        h5 {
                            font-size: 26px;
                        }

                        p {
                            font-size: 23px;
                        }
                    }
                }
            }
        }

        .whocanJoin1 {
            margin-top: 140px;

            .text-data {
                h3 {
                    font-size: 35px;
                }

                h4 {
                    font-size: 33px;
                    margin-top: 30px;
                }

                h6 {
                    font-size: 24px;


                }
            }
        }

    }

    .Policy {
        padding-top: 140px;

        p {

            font-size: 22px;
        }

        .policybox {

            .cardbox {
                padding: 20px 35px;

                h4 {
                    font-size: 24px;
                }

                h6 {
                    margin-top: 12px !important;
                    font-size: 20px;

                }


            }
        }

        .bottomText {

            font-size: 18px;

        }
    }

    .contact-page {
        padding-top: 140px;

        .contact-box {
            .sub-box {
                p {
                    font-size: 18px;
                }

                .contact-form {

                    .form-grp {
                        .labeltext {

                            font-size: 18px;

                        }

                        .inputfield {
                            font-size: 22px;

                        }
                    }
                }

                .address {
                    .address-details {
                        font-size: 22px;

                        span {
                            font-size: 24px !important;

                        }
                    }
                }
            }
        }
    }

    .Error-Page {
        padding-top: 120px;

        .text {

            h5 {
                margin: 30px 0px 40px;
                font-size: 24px;
                font-weight: 400;
                line-height: 31.48px;
                color: $color-grey3;
            }
        }
    }
}