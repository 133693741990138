$Bluecolor: #3A6AD6;
$fontFamily: "Mulish", sans-serif;
nav {
    transition: all 0.5s ease;
    .updated-logo{
        .logo2-image{
            animation:  revealFromLeft 3s linear infinite;
            // animation: revealFromLeft 2s forwards;
        }

        .logoText{
            font-size: 22px;
            font-weight: 400;
            color: $Bluecolor;
            span{
                font-weight: 700;
            }
        }
    }
&.scrolled{
    box-shadow: 1px 1px 20px 1px $Bluecolor;
}
    .btn {
        border: none;
        padding: 12px 16px;
        border-radius: 16px;
        background-color: $Bluecolor;
        color: #fff;
        font-weight: 500;
        font-size: 16px;
        font-family: $fontFamily;
               box-shadow: -2px -2px 4px 0px #00000040 inset,0px 3px 8px 0px #FFFFFF66 inset;
               &:hover{
                transition: all .1s ease-in;
                background-color: $Bluecolor !important;
                color: #fff !important;
                border: 2px solid $Bluecolor;
                box-shadow: none !important;
               }
    }

    li.nav-item a {
        color: black ;
        font-size: 16px;
        font-weight: 600;
        font-family: $fontFamily;
        &:hover{
            color: $Bluecolor !important;
        }
    }

    &.scrolled,
    &.opened {
        background: white;
    }

    .btn:hover {
        transition: all .3s ease-in;
        background-color: #fff !important;
        color: $Bluecolor !important;
        border: 2px solid $Bluecolor;
        box-shadow: none !important;
    }
}

.navbar-toggler:focus {
    box-shadow: none;
}

@media (min-width:992px) {
    nav {
        li.nav-item a {
            font-size: 14px !important;
            margin-right: 5px !important;
            margin-left: 5px !important;
        }
    }
}

@media (min-width:1024px) {
    nav {
        li.nav-item a {
            margin-right: 5px !important;
            margin-left: 5px !important;

        }
    }
}

@media (min-width:1200px) {
    nav {
        li.nav-item a {
            margin-right: 17.5px !important;
            margin-left: 17.5px !important;
        }
    }
}

@media (min-width:1400px) {
    nav {
        li.nav-item a {
            font-size: 16px !important;
            margin-right: 25px !important;
            margin-left: 25px !important;
        }
    }
}
@media (min-width:1800px) {
    nav {
        li.nav-item a {
            font-size: 20px !important;
            margin-right: 30px !important;
            margin-left: 30px !important;
        }
        .btn {
            padding: 12px 16px !important;
            font-size: 20px !important;
        }
    }
}
@keyframes revealFromLeft {
    // 0% {
    //   clip-path: inset(0 100% 0 0);
    // }
    // 100% {
    //   clip-path: inset(0 0 0 0);
    // }
    0% {
        clip-path: inset(0 100% 0 0);
      }
      50% {
        clip-path: inset(0 0 0 0);
      }
      100% {
        clip-path: inset(0 100% 0 0);
      }
  }